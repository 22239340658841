<template>
  <div class="box-container">
    <div class="d-flex justify-space-between flex-wrap mb-5">
      <div class="d-flex align-center">
        <v-avatar tile size="25" class="me-3">
          <img src="/img/user_filled.png" alt="" />
        </v-avatar>
        <h2 class="mb-0">{{ $t("EditProfile.editProfile") }}</h2>
      </div>
      <v-btn
        outlined
        color="primary"
        class="text-capitalize font-600"
        @click="() => $router.push(`/entities/${$route.params.entidadId}/account`).catch(() => {})"
      >
        {{ $t("EditProfile.backToProfile") }}
      </v-btn>
    </div>

    <v-row>
      <v-col cols="12">
        <base-card>
          <v-form ref="profileForm">
            <div class="pa-5">
              <div class="mb-7">
                <label for="cover-upload-avatar">
                  <v-avatar size="64" class="cursor-pointer">
                    <v-img :src="photo || '/img/avatars/001-man.png'"></v-img>
                  </v-avatar>
                </label>
                <input
                  id="cover-upload-avatar"
                  type="file"
                  class="d-none"
                >
              </div>

              <v-row>
                <v-col cols="12" lg="6">
                  <p class="text-14 mb-1">
                    {{ $t("EditProfile.firstname") }}
                  </p>
                  <v-text-field
                    v-model="profile.nombre"
                    outlined
                    dense
                    hide-details=""
                    class=""
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="text-14 mb-1">
                    {{ $t("EditProfile.lastname") }}
                  </p>
                  <v-text-field
                    v-model="profile.apellidos"
                    outlined
                    dense
                    hide-details=""
                    class=""
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="text-14 mb-1">
                    {{ $t("EditProfile.email") }}
                  </p>
                  <v-text-field
                    v-model="profile.mail"
                    outlined
                    dense
                    hide-details=""
                    class=""
                  />
                </v-col>
                <v-col cols="12" lg="6">
                  <p class="text-14 mb-1">
                    {{ $t("EditProfile.phone") }}
                  </p>
                  <v-text-field
                    v-model="profile.telefono"
                    outlined
                    dense
                    hide-details=""
                    class=""
                  />
                </v-col>
                <!-- <v-col cols="12" lg="6">
                          <p class="text-14 mb-1">Birth Date</p>
                          <v-text-field
                            :value="profile.fechaCreacion"
                            type="date"
                            outlined
                            dense
                            hide-details=""
                            class=""
                          />
                        </v-col> -->
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    class="text-capitalize font-600"
                    @click="() => saveChanges()"
                  >
                    {{ $t("EditProfile.saveChanges") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      profile: (state) => state.authentication.profile,
    }),
    photo() {
      let usuarioId = this.profile.usuarioId;

      if (usuarioId) {
        return `${axios.defaults.baseURL}/api/v1/users/${this.profile.usuarioId}/photo`;
      } else {
        return null;
      }
    },
  },
  data() {
    return {
      isSidebar: false,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
    };
  },
  methods: {
    saveChanges() {
      let success = this.$refs.profileForm.validate();

      if (success) {
        this.$store
          .dispatch("authentication/setProfile", this.profile)
          .then((profile) => {
            this.$console.info("PROFILE =>", profile);
          })
          .catch((err) => {
            this.$console.error('PROFILE =>', err);
          });
      }
    },
  },
};
</script>
